@import '~/sass/colors.scss';
@import '~/sass/mixins.scss';
@import '~/sass/vars.scss';

#menu-navigation {
  display: flex;
  position: sticky;
  flex-direction: column;
  font-size: 0.875rem;
  height: 100vh;
  overflow-y: auto;
  padding-left: 2 * $spacing;
  top: 0;
  width: 310px;
  @include border-radius(0 24px 24px 0px);
  @include transitions(width 0.3s ease-in-out, padding 0.3s ease-in-out);
  @include scrollbar-none;

  &.collapsed {
    padding: 2 * $spacing;
    width: 68px;

    .header {
      padding: 0;

      .logo-container {
        margin-left: -0.5 * $spacing;
        padding: 0;
        gap: 0;

        .logo {
          width: 42px;
        }
        .name {
          width: 0;
        }
      }
    }

    .body {
      padding: 0;
    }

    .footer {
      align-items: center;
    }
  }

  .header {
    display: flex;
    padding: 5 * $spacing 0 7 * $spacing;
    @include transition(padding, 0.3s, ease-in-out);

    .logo-container {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      gap: 2 * $spacing;
      padding: 0 $spacing;
      @include border-radius($spacing);
      @include transition(margin, 0.3s, ease-in-out);

      .logo {
        width: 54px;
        @include transition(width, 0.3s, ease-in-out);
      }

      .name {
        width: 85px;
        @include transition(width, 0.3s, ease-in-out);
      }
    }
  }

  .collapse-button {
    position: absolute;
    top: 24px;
    right: 0;
    background: none;
    border: none;
    height: 82px;
    padding: 0;
    animation: appear 0.3s ease-in-out;

    @keyframes appear {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    &:hover,
    &:active,
    &:focus {
      backdrop-filter: unset;
      background: none;
    }

    .toggle {
      height: 100%;
    }

    .arrow {
      position: absolute;
      bottom: 50%;
      left: 10px;
      height: 10px;
      transform: translate(0, 50%) rotate(180deg);
    }
  }

  .reverse-arrow {
    .arrow {
      transform: rotate(180deg);
    }
  }

  .expand-button {
    position: relative;
    display: flex;
    background: none;
    border: none;
    margin-top: 3 * $spacing;
    max-width: 54px;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      backdrop-filter: unset;
      background: none;
    }

    .toggle {
      margin: auto;
    }

    .arrow {
      position: absolute;
      bottom: 50%;
      right: 50%;
      height: 10px;
      transform: translate(50%, 50%);
    }
  }

  .body {
    flex: 1 0 200px;
    overflow-y: auto;
    padding-right: 2 * $spacing;
    @include scrollbar-none;

    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5 * $spacing;
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 3 * $spacing;
    padding: 3 * $spacing 0 2 * $spacing;

    a {
      text-decoration: none;
    }

    button {
      background: none;
      border: none;
    }

    a,
    button {
      display: flex;
      gap: $spacing;
      padding: 0 $spacing;
      @include border-radius($spacing);
    }
  }

  .single-line {
    flex: 1 1 0;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@import '~/sass/vars.scss';
@import '~/sass/default.scss';
@import '~/sass/mixins.scss';
@import '~/sass/layout-mixins.scss';

#profile {
  .container {
    position: relative;
    display: flex;
    padding: 8 * $spacing;
  }
}

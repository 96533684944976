@import '~/sass/vars.scss';

#workbook-view {
  min-height: 100%;
  position: relative;
  z-index: 0;

  .outside {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -600px;
    width: 600px;
    z-index: -1;
  }

  .outside-right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -40px;
    z-index: -1;
    width: 30vw;
  }

  &.loader {
    display: flex;
    align-items: center;
    height: 50%;
    justify-content: center;
  }

  .container {
    overflow: auto;
  }

  .view-container {
    display: flex;

    .view {
      margin: 3 * $spacing auto 0;
    }
  }
}

@import './vars.scss';
@import './colors.scss';
@import './mixins.scss';

@mixin root {
  font-family: $font-family !important;
  letter-spacing: 0.1px;
}

@mixin item {
  background-color: $item-background;
  color: $font-color;
  border-radius: 25px;

  &.item-text {
    padding: 24px;
  }

  &div {
    text-align: center;
  }
}

@mixin item-container {
  background-color: $item-container-background;
  border-radius: 18px;

  .item,
  .item-text {
    @include item;
  }
}

#splash {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .splash {
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }
}

@import '~/sass/colors.scss';
@import '~/sass/vars.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

.color-main {
  color: $color-main;
}

.color-contrast {
  color: $color-contrast;
}

.color-light {
  color: $color-light;
}

.color-dark {
  color: $color-dark;
}

.background-main {
  background-color: $color-main;
}

.background-contrast {
  background-color: $color-contrast;
}

.background-light {
  background-color: $color-light;
}

.background-dark {
  background-color: $color-dark;
}

.theme-main {
  @extend .background-main;
  @extend .color-contrast;
}

.theme-inverse {
  @extend .background-contrast;
  @extend .color-main;
}

.theme-dark {
  @extend .background-dark;
  @extend .color-contrast;
}

.theme-dark-inverse {
  @extend .background-contrast;
  @extend .color-dark;
}

.outlined {
  border: thin solid currentColor;
}

.text {
  background: none;
  padding: 0;
}

.error {
  color: $error-main;
}

button {
  display: flex;
  align-items: center;
  border-radius: $spacing;
  border: none;
  cursor: pointer;
  gap: $spacing;
  padding: $spacing;
}

button:hover,
button:active,
button:focus {
  backdrop-filter: brightness(95%);
}

input {
  display: block;
  background: $root-background;
  border: none;
  padding: $spacing;
}

[type='checkbox'] {
  display: unset;
  margin: 0;
  width: 24px;
  height: 24px;
}

label.checkbox {
  display: flex;
  align-items: center;
  gap: $spacing;
}

#loch-form {
  .rounded {
    border-radius: 0.75rem;
  }
  @extend .rounded;
  @extend .background-contrast;
  display: flex;
  flex-direction: column;
  gap: 3 * $spacing;
  margin-left: auto;
  margin-right: auto;
  max-width: 95%;
  min-width: 40%;
  min-height: 80%;
  padding: 8 * $spacing;

  h1 {
    text-align: center;
    font-size: 40px;
    color: $font-color;
  }

  h2 {
    text-align: center;
    font-size: 15px;
    color: $font-color;
  }

  .actions {
    display: grid;
    gap: 2 * $spacing;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    input {
      cursor: pointer;
    }
  }

  .secondary-actions {
    display: flex;
    gap: 2 * $spacing;
    justify-content: flex-end;

    input {
      cursor: pointer;
    }
  }
}

.MuiCardHeader-title {
  font-size: 2rem !important;
  font-weight: 500 !important;
  color: $color-dark;
  font-family: $font-family;
}

.MuiCardHeader-avatar {
  color: $color-main;
  font-size: 2rem !important;
}

.MuiSvgIcon-root {
  font-size: 2rem !important;
}

.MuiOutlinedInput-root {
  border-radius: 20px !important;
  background-color: rgb(240, 240, 240);
}
.MuiOutlinedInput-input {
  font-family: $font-family !important;
}
.MuiTypography-root {
  font-family: $font-family !important;
}

.MuiPaper-root {
  border-radius: 20px !important;
}

.MuiCardHeader-root {
  border-bottom: 1px solid !important;
  border-color: 'grey.300' !important;
  margin: 3 * $spacing !important;
}

.profile-label {
  color: $color-main;
  font-family: $font-family;
  font-weight: 1000;
}

.MuiDataGrid-columnHeaderTitleContainerContent {
  color: $color-main !important;
  font-family: $font-family !important;
}

.MuiDataGrid-columnHeadersInner {
  background-color: rgb(240, 240, 240);
}

.MuiDataGrid-cellContent {
  font-family: $font-family !important;
}

.MuiTablePagination-selectLabel {
  font-family: $font-family !important;
}

.MuiInputBase-root-MuiTablePagination-select {
  font-family: $font-family !important;
}

.MuiTablePagination-displayedRows {
  font-family: $font-family !important;
}

.MuiSelect-select-MuiInputBase-input {
  font-family: $font-family !important;
}

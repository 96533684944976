@import '~/sass/vars.scss';
@import '~/sass/default.scss';
@import '~/sass/mixins.scss';
@import '~/sass/layout-mixins.scss';

#tenant {
  .container {
    position: relative;
    display: flex;
    padding: 8 * $spacing;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.tenant-info {
  display: flex;
  flex-direction: row;
  gap: 10 * $spacing;
  margin-left: auto;
  margin-right: auto;
}

#loch-form {
  overflow: auto;
  .form-input {
    width: 100%;
  }
  .users-container {
    position: relative;
    display: flex;
    padding: 8 * $spacing;
    min-width: 95%;
  }
  .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2 * $spacing;
    gap: 3 * $spacing;
    margin-top: 3 * $spacing;
    .user-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .user-name {
      font-weight: 600;
      margin-right: auto;
    }

    .user-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-left: auto;
      gap: $spacing;
      .user-action {
        margin-left: 2 * $spacing;
      }
    }
  }
}
.input-wrapper {
  width: 100%;
  margin-bottom: 2em;

  input[type='text'],
  input[type='password'],
  input[type='date'] {
    font-family: 'Montserrat';
    font-weight: lighter;
    color: $font-color;
    outline: none;
    border: 1px solid #efeded;
    width: 110%;
    max-width: 110%;

    @include placeholder {
      color: $font-color;
    }
  }
}

@import '~/sass/breakpoints.scss';
@import '~/sass/colors.scss';
@import '~/sass/vars.scss';

#authenticator {
  display: grid;
  min-height: 100vh;
  grid-template-columns: 1fr;

  @media (min-width: $md) {
    grid-template-columns: 1fr 1fr;
  }

  .visual {
    position: relative;
    align-items: center;
    background: $root-background-dark linear-gradient(transparent, rgba(0, 0, 0, 0.3));
    border-radius: 0 0 80px 0;
    display: flex;
    flex-direction: column;
    gap: 4 * $spacing;
    padding-bottom: 8 * $spacing;
    z-index: 0;

    @media (min-width: $md) {
      border-radius: 0 80px 80px 0;
    }
  }

  .visual::before {
    content: '';
    background-image: url('/images/logo-standalone.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: brightness(20%);
    opacity: 0.06;
    z-index: -10;
  }

  .logos {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2 * $spacing;
    margin: auto;

    .logo {
      background: transparent;
      border: none;
      margin: 0;
      padding: 0;
      width: 200px;
    }
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

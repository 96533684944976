@import '~/sass/colors.scss';

#authenticated-wrapper {
  display: flex;
  background-color: $root-background;
  min-height: 100vh;

  .menu-content {
    z-index: 1;
  }

  .comment-content {
    z-index: 1;
  }

  .authenticated-content {
    flex-grow: 1;

    &:focus {
      outline: none;
    }
  }
}

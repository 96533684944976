:root {
  --color-main: unset;
  --color-contrast: unset;
  --color-light: unset;
  --color-dark: unset;
}

$color-main-value: #208399;
$color-contrast-value: #ffffff;
$color-light-value: #71b7c7;
$color-dark-value: #025a7a;

$root-background: #f0f0f1;
$root-background-dark: #02516e;
$font-color: #2e3e40;
$item-background: #ffffff;
$item-container-background: #efeded;

$error-main: #a73641;

$color-main: var(--color-main, $color-main-value);
$color-contrast: var(--color-contrast, $color-contrast-value);
$color-light: var(--color-light, $color-light-value);
$color-dark: var(--color-dark, $color-dark-value);

:export {
  colorMain: $color-main-value;
  colorLight: $color-light-value;
  rootBackground: $root-background;
}

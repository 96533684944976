@import '~/sass/colors.scss';
@import '~/sass/default.scss';
@import '~/sass/mixins.scss';
@import '~/sass/vars.scss';

#menu-item {
  display: flex;
  flex-direction: column;
  @include border-radius(10px);

  a,
  button {
    display: flex;
    align-items: center;
    border: none;
    flex: 1 1 auto;
    gap: 2 * $spacing;
    justify-content: space-between;
    text-align: start;
    padding: 1.5 * $spacing;
    text-decoration: none;
    word-break: break-word;
    @include border-radius(10px);
  }

  a:hover,
  a:active,
  a:focus,
  button:hover,
  button:active,
  button:focus {
    background: rgba(43, 48, 53, 0.1);
  }

  .arrow {
    flex: 0 0 10px;
  }

  &.active,
  .active {
    @extend .background-light;
    @include border-radii(10px, 10px, 10px, 10px);

    a {
      @extend .color-dark;
      @include border-radii(10px, 10px, 10px, 10px);
    }
  }

  &.is-expanded {
    @include darkened-background($color-light, 0.15);

    button {
      @extend .color-dark;
    }

    & > button > .arrow {
      transform: rotate(90deg);
    }
  }
}

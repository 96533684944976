@import '~/sass/colors.scss';
@import '~/sass/vars.scss';

#authenticator-form {
  display: flex;
  flex-direction: column;
  gap: 3 * $spacing;
  justify-content: center;
  margin: auto;
  max-width: 600px;
  min-height: 80%;
  padding: 8 * $spacing;

  h1 {
    text-align: center;
    font-size: 40px;
    color: $font-color;
  }

  .actions {
    display: grid;
    gap: 2 * $spacing;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    input {
      cursor: pointer;
    }
  }

  .secondary-actions {
    display: flex;
    gap: 2 * $spacing;
    justify-content: flex-end;

    input {
      cursor: pointer;
    }
  }
}

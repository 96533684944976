@import '~/sass/colors.scss';
@import '~/sass/default.scss';
@import '~/sass/mixins.scss';
@import '~/sass/vars.scss';

#comment-item {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-top: 2%;
  @include border-radius(10px);

  .comment-box {
    border-top: 2px solid #f0f0f1;
    border-right: 2px solid #f0f0f1;
    border-bottom: 2px solid #f0f0f1;
    border-left: 5px solid #72b6c6;
    border-radius: 5px 15px 15px 5px;
    margin-right: 3%;
  }

  .body-box {
    border-bottom: 2px solid #f0f0f1;
    border-radius: 0px 30px 30px 0px;
  }

  .footer-box {
    height: 40px;
  }

  .border-custom {
    width: 12px;
    background-color: #72b6c6;
  }

  .text {
    color: #003041;
    font-size: 8px;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 20px;
  }

  .date-text {
    height: 32px;
    width: 623px;
    color: #a3a8aa;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
  }

  .commented-text {
    color: #a3a8aa;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
  }

  .comment-date-text {
    color: #a3a8aa;
    font-family: Poppins;
    font-size: 0.5vw;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
    text-align: end;
    padding-right: 10%;
  }

  .username-text {
    color: #025573;
    font-family: Poppins;
    font-size: 0.65vw;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
    text-align: start;
    margin-left: 10%;
  }

  .comment-text {
    color: #000000;
    font-family: Poppins;
    font-size: 0.6vw;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin-left: 5%;
  }

  .avatar {
    background: #72b6c6;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    font-weight: 600;
  }

  .view-comment {
    box-sizing: border-box;
    width: 100%;
  }

  .liked {
    height: 26px;
    width: 26px;
    margin-left: 5%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .delete {
    height: 16px;
    width: 16px;
    color: red;
    margin-left: 5%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .tooltip-like {
    border-color: #000000;
    background-color: #003041;
  }
}

@import '~/sass/default.scss';
@import '~/sass/vars.scss';

#custom-views-dialog {
  position: relative;
  z-index: 50;

  .outer {
    position: fixed;
    display: flex;
    background: rgba($color: black, $alpha: 0.2);
    inset: 0;
    overflow: auto;

    .panel {
      @extend .theme-dark-inverse;
      border-radius: 4 * $spacing;
      margin: auto;
      min-width: 50%;

      h1 {
        @extend .color-dark;
        font-weight: bold;
        font-size: large;
        margin-bottom: $spacing;
      }

      .title {
        @extend .theme-dark;
        display: flex;
        align-items: center;
        border-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        gap: 2 * $spacing;
        padding: 3 * $spacing 4 * $spacing;
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 2 * $spacing;
        padding: 4 * $spacing;
      }
    }
  }
}

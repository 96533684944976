@import '~/sass/colors.scss';
@import '~/sass/mixins.scss';
@import '~/sass/vars.scss';
@import '~/sass/default.scss';

#comment-navigation {
  display: flex;
  position: sticky;
  flex-direction: column;
  font-size: 0.875rem;
  overflow-y: auto;
  top: 0;
  width: 23vw;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.24);
  border-radius: 30px 0px 0px 30px;
  height: 100vh;
  @include border-radius(24px 0px 0px 24px);
  @include transitions(width 0.3s ease-in-out, padding 0.3s ease-in-out);
  @include scrollbar-none;

  &.collapsed {
    padding: 2 * $spacing;
    width: 68px;

    .header {
      padding: 0;

      .logo-container {
        margin-left: 0.5 * $spacing;
        padding: 0;
        gap: 0;

        .logo {
          width: 42px;
        }
        .name {
          width: 0;
        }
      }
    }

    .body {
      padding: 0;
      background-color: #025a7a;
    }

    .footer {
      align-items: center;
      background-color: #025a7a;
    }
  }

  .header {
    display: flex;
    padding: 5 * $spacing 0 7 * $spacing;
    @include transition(padding, 0.3s, ease-in-out);

    .logo-container {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      gap: 2 * $spacing;
      padding: 0 $spacing;
      margin-left: 10%;
      margin-top: 2%;
      @include border-radius($spacing);
      @include transition(margin, 0.3s, ease-in-out);

      .logo {
        width: 54px;
        @include transition(width, 0.3s, ease-in-out);
      }

      .name {
        width: 85px;
        @include transition(width, 0.3s, ease-in-out);
      }
    }
    padding-left: 5%;
  }

  .title {
    padding-left: 5px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
  }

  .collapse-button {
    position: absolute;
    top: 24px;
    left: 0;
    background: none;
    border: none;
    height: 82px;
    padding: 0;
    animation: appear 0.3s ease-in-out;
    transform: rotate(180deg);
    @keyframes appear {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    &:hover,
    &:active,
    &:focus {
      backdrop-filter: unset;
      background: none;
    }

    .toggle {
      height: 100%;
    }

    .arrow {
      position: absolute;
      bottom: 50%;
      left: 10px;
      height: 10px;
      transform: translate(50%, 50%) rotate(180deg);
    }
  }

  .expand-button {
    position: relative;
    display: flex;
    background: none;
    border: none;
    margin-top: 3 * $spacing;
    max-width: 54px;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      backdrop-filter: unset;
      background: none;
    }

    .toggle {
      margin: auto;
    }

    .arrow {
      position: absolute;
      bottom: 50%;
      right: 50%;
      height: 10px;
      transform: translate(0, 50%) rotate(180deg);
    }
  }

  .body {
    flex: 1 0 200px;
    overflow-y: auto;
    padding-left: 2 * $spacing;
    padding-bottom: 2 * $spacing;
    background-color: #ffffff;
    @include scrollbar-none;

    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5 * $spacing;
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 3 * $spacing;
    padding: 3 * $spacing 0 2 * $spacing;
    // height: 20vh;
    background-color: #f0f0f1;

    a {
      text-decoration: none;
    }

    button {
      background: none;
      border: none;
    }

    a,
    button {
      display: flex;
      gap: $spacing;
      padding: 0 $spacing;
      @include border-radius($spacing);
    }

    .text {
      color: #025573;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 28px;
      margin-left: 5%;
    }

    .attachment-text {
      color: #a3a8aa;
      font-family: Poppins;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    .attachment-container {
      display: flex;
      margin-left: 5%;
    }

    .input {
      background-color: #ffffff;
      color: #a3a8aa;
      margin-left: 5%;
      margin-right: 5%;
    }

    .actions {
      display: grid;
      gap: 2 * $spacing;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      padding-right: 5%;
      padding-left: 5%;
      input {
        cursor: pointer;
      }
    }
  }

  .single-line {
    flex: 1 1 0;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

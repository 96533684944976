@import '~/sass/vars.scss';
@import '~/sass/default.scss';
@import '~/sass/mixins.scss';
@import '~/sass/layout-mixins.scss';

#view-list {
  display: grid;
  gap: 3 * $spacing;
  grid-template-columns: repeat(auto-fill, 280px);
  justify-content: space-between;

  .rounded {
    border-radius: 0.75rem;
  }

  .container {
    @extend .rounded;
    @extend .background-contrast;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .name {
    line-height: 1.5rem;
    height: 3rem;
    margin: auto 3 * $spacing;
    @include line-clamp(2);
  }

  .overlay {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 0;
    padding: 3 * $spacing;

    .view-dashboard {
      @extend .theme-main;
      @extend .rounded;
      width: 100%;
      padding: 1.5 * $spacing;
      text-align: center;
    }
  }

  .container:hover .overlay,
  .container:active .overlay,
  .container:focus .overlay {
    @extend .rounded;
    border: thick solid $color-main;
    opacity: 1;
  }
}
